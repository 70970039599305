import { useSmartrrVendorSelector } from "@vendor-app/app/_state/typedVendorReduxHooks";
import { useMemo } from "react";

export const useHasTrialExpired = () => {
  const { activePlan } = useSmartrrVendorSelector(state => state.accountPlans);
  const isSuperUser = useSmartrrVendorSelector(state => state.auth.user?.isSuperUser);
  const noActivePlan = !activePlan || activePlan.status === "EXPIRED_TRIAL";

  return useMemo(() => noActivePlan && !isSuperUser, [activePlan, isSuperUser]);
};
